/* eslint-disable */
export const feedbackFormAgreement = `
<p>
    Настоящим Я, в соответствии со
    <a
    >
        статьей 9
    </a>
    <a
    >
    </a>
    Федерального закона от 27.07.2006 № 152-ФЗ "О персональных данных", в целях
    направления мною обращения посредством заполнения электронной формы,
    рассмотрения Оператором указанного обращения, предоставления Оператором
ответа на моё обращение по электронной почте или по телефону, даю согласие    <a>Обществу с ограниченной ответственностью </a>
    <a>
        «Каршеринг Руссия» (ОГРН 1237700701534, ИНН 9718236471), юридический
        адрес: 107023, г. Москва, ул. Электрозаводская, дом 27, ст.1А, эт. 1,
        пом. IV, ком. 26
    </a>
    , именуемому также – «Оператор»,
</p>
<p>
    на автоматизированную, а также без использования средств автоматизации,
    обработку моих персональных данных, включая сбор, запись, систематизацию,
    накопление, хранение, уточнение (обновление, изменение), извлечение,
    использование, передачу (предоставление, доступ), обезличивание,
    блокирование, удаление, уничтожение персональных данных.
</p>
<p>
    Перечень моих персональных данных, на обработку которых я даю согласие:
    фамилия, имя, номер мобильного телефона, адрес личной электронной почты
    (e-mail).
</p>
<p>
    Срок действия моего согласия на обработку персональных данных – 90
    (девяносто) дней. Данное согласие на обработку персональных данных может
    быть отозвано в любой момент посредством направления Оператору письменного
    уведомления.
</p>
<p>
    Я уведомлен(а), что при отзыве мной согласия на обработку персональных
    данных Оператор вправе продолжить обработку моих персональных данных в
    случаях, предусмотренных Федеральным законом от 27.07.2006 № 152-ФЗ «О
    персональных данных». При достижении целей обработки мои персональные
    данные подлежат уничтожению в сроки, установленные законодательством РФ.
</p>
<p>
    Подтверждаю, что анкета заполняется мной добровольно, и сведения, указанные
    в анкете, являются достоверными.
</p>
<p>
    Нажимая кнопку "согласен", я подтверждаю свое согласие на обработку
    персональных данных.
</p>
<p>
    <strong> </strong>
</p>
`;
