<template>
  <div class="fos-agreement half__text">
    <div class="agreement" v-once v-html="feedbackFormAgreement" />
    <VButton type="button">
      <router-link :to="{ name: 'FOSForm' }">
        {{ $t('lang.menu.backButton') }}
      </router-link>
    </VButton>
  </div>
</template>

<script>
import VButton from '@/components/ui/VButton';
import { feedbackFormAgreement } from '../docs/feedback-form-agreement';

export default {
  name: 'FOSAgreement',
  components: {
    VButton,
  },
  data() {
    return {
      feedbackFormAgreement,
    };
  },
};
</script>
